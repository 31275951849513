import React from "react";
import { Typography, Col, Row, Carousel } from "antd";
import { StaticImage } from "gatsby-plugin-image";
const { Title, Paragraph } = Typography;

const FeatureBlock = ({
  title,
  description,
  title2,
  description2,
  title3,
  description3,
}) => {
  return (
    <>
      <Row
        justify="center"
        align="middle"
        className="section"
        style={{ backgroundColor: "#f1f3f5" }}
      >
        <Col xs={22} sm={20} md={10} lg={8} xl={{ span: 6, offset: 1 }}>
          <Col>
            <Title level={3} className="typography-nice">
              {title}
            </Title>
            <Paragraph className="feature-description">{description}</Paragraph>
          </Col>
          <Col>
            <Title level={3} className="typography-nice">
              {title2}
            </Title>
            <Paragraph className="feature-description">
              {description2}
            </Paragraph>
          </Col>
          <Col>
            <Title level={3} className="typography-nice">
              {title3}
            </Title>
            <Paragraph className="feature-description">
              {description3}
            </Paragraph>
          </Col>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={10}
          lg={8}
          xl={{ span: 7, offset: 1 }}
          style={{ backgroundColor: "#e9ecef" }}
        >
          <Row justify="center">
            <Col>
              <div className="device device-iphone-x">
                <div
                  className="device-frame"
                  style={{ boxShadow: "0 -2px 12px rgba(0,0,0,0.3)" }}
                >
                  <Carousel
                    autoplay
                    autoplaySpeed={7500}
                    dotPosition="top"
                    effect="fade"
                  >
                    <div
                      className="device-content"
                      style={{ borderRadius: "32px" }}
                    >
                      <StaticImage
                        src="../../images/screens/acute-app.jpeg"
                        alt="Phone Example"
                        style={{ borderRadius: "32px" }}
                      />
                    </div>
                    <div
                      className="device-content"
                      style={{ borderRadius: "32px" }}
                    >
                      <StaticImage
                        src="../../images/screens/katnh.png"
                        alt="Phone Example"
                        style={{ borderRadius: "32px" }}
                      />
                    </div>
                    <div
                      className="device-content"
                      style={{ borderRadius: "32px" }}
                    >
                      <StaticImage
                        src="../../images/screens/lta.png"
                        alt="Phone Example"
                        style={{ borderRadius: "32px" }}
                      />
                    </div>
                  </Carousel>
                </div>
                <div className="device-stripe"></div>
                <div className="device-header"></div>
                {/* <div className="device-sensors"></div> */}
                <div className="device-btns"></div>
                <div className="device-power"></div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default FeatureBlock;
