import React from "react";
import { Carousel, Col, Row, Typography } from "antd";
import Highlight, { defaultProps } from "prism-react-renderer";

const { Paragraph, Title } = Typography;
const exampleCode = `
<iframe 
  id="myChartId"
  src="MyUrl"
>
</iframe>
<script>
  iFrameResize({ 
    checkOrigin: true
  }, '#myChartId')
</script>
`;

const exampleCodeLink = `
<a 
  href="MyUrl" 
  target="_blank" 
  rel="noreffer noopener"
>
  Link to chart
</a>
`;

const FeatureEmbed = () => {
  return (
    <>
      <Row
        justify="center"
        className="section"
        style={{ backgroundColor: "#fff", padding: "96px 0" }}
      >
        <Col xs={20} sm={20} md={20} lg={16} xl={12}>
          <Row justify="center">
            <Col>
              <Title
                level={2}
                className="typography-nice"
                style={{ textAlign: "center" }}
              >
                Easy integration with your product
              </Title>
              <Paragraph
                style={{ fontSize: "125%", zIndex: "1", textAlign: "center" }}
              >
                Include inFlow charts in your product by embed or fullscreen
                chart view, easy.
              </Paragraph>
            </Col>
          </Row>
          <Row justify="center" style={{ padding: "24px 0" }}>
            <Col
              xs={22}
              sm={12}
              md={12}
              lg={11}
              xl={8}
              offset={0}
              style={{ textAlign: "center" }}
            >
              <Title level={4}>Smart embed</Title>
              <Paragraph>
                We use scalable embedding technology which provides a perfect
                experience for your user.
              </Paragraph>
            </Col>
            <Col
              xs={22}
              sm={12}
              md={12}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 8, offset: 1 }}
              style={{ textAlign: "center" }}
            >
              <Title level={4}>Fullscreen chart view</Title>
              <Paragraph>
                Link to your chart via the chart viewer. Short URLs for easy
                sharing are provided.
              </Paragraph>
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={16} sm={12} md={10} lg={9} xl={9} className="code-example">
              <Carousel
                autoplay
                style={{
                  backgroundColor: "#272822",
                  borderRadius: "3px",
                  padding: "0 10px",
                }}
              >
                <Highlight
                  {...defaultProps}
                  code={exampleCode}
                  theme={undefined}
                  language="html"
                >
                  {({
                    className,
                    style,
                    tokens,
                    getLineProps,
                    getTokenProps,
                  }) => (
                    <pre className={className} style={{ minHeight: "210px" }}>
                      {tokens.map((line, i) => (
                        <div {...getLineProps({ line, key: i })}>
                          {line.map((token, key) => (
                            <span {...getTokenProps({ token, key })} />
                          ))}
                        </div>
                      ))}
                    </pre>
                  )}
                </Highlight>
                <Highlight
                  {...defaultProps}
                  code={exampleCodeLink}
                  theme={undefined}
                  language="html"
                >
                  {({
                    className,
                    style,
                    tokens,
                    getLineProps,
                    getTokenProps,
                  }) => (
                    <pre className={className} style={{ minHeight: "210px" }}>
                      {tokens.map((line, i) => (
                        <div {...getLineProps({ line, key: i })}>
                          {line.map((token, key) => (
                            <span {...getTokenProps({ token, key })} />
                          ))}
                        </div>
                      ))}
                    </pre>
                  )}
                </Highlight>
              </Carousel>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default FeatureEmbed;
