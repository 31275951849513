import React from "react";
import { Col, Row, Typography, Button } from "antd";
import Xarrow, { Xwrapper } from "react-xarrows";
import { navigate } from "gatsby";
import TextLoopTag from "./TextLoopTag";
const { Title } = Typography;
const HeadingHero = () => {
  return (
    <>
      <Row justify="center" align="middle" className="heading-hero">
        <Col xs={22} sm={20} md={16} lg={12} xl={10}>
          <Xwrapper>
            <Title
              style={{ lineHeight: "1.44" }}
              className="typography-nice alt tag"
            >
              Turn your
              <br />
              <TextLoopTag />
              <br />
              into an interactive
              <br />
              decision-making journey<span id="arrow1"></span>
            </Title>
            <Button
              id="arrow2"
              size="large"
              style={{
                marginTop: "20px",
                marginBottom: "68px",
                fontSize: "18px",
                backgroundColor: "#EBD247",
                borderColor: "#EBD247",
              }}
              onClick={(event) => {
                event.preventDefault();
                navigate("/get-a-quote");
              }}
            >
              Get a quote
            </Button>
            <Xarrow
              start="arrow1"
              end="arrow2"
              color="#EBD247"
              headSize="4"
              strokeWidth="4"
            />
          </Xwrapper>
        </Col>
      </Row>
    </>
  );
};

export default HeadingHero;
