import React from "react";
import TextLoop from "react-text-loop";

const TextLoopIndexTag = () => {
  return (
    <>
      <TextLoop interval={1500}>
        <span style={{ fontStyle: "italic" }}>process</span>
        <span style={{ fontStyle: "italic" }}>flow chart</span>
        <span style={{ fontStyle: "italic" }}>decision tree</span>
        <span style={{ fontStyle: "italic" }}>sequential diagram</span>
        <span style={{ fontStyle: "italic" }}>algorithm</span>
        <span style={{ fontStyle: "italic" }}>stepped content</span>
        <span style={{ fontStyle: "italic" }}>form</span>
      </TextLoop>
    </>
  );
};

export default TextLoopIndexTag;
