import React, { useState } from "react";
import { Row, Col, Spin, Tabs, PageHeader, Button } from "antd";
import Loadable from "react-loadable";
import { navigate } from "gatsby";
import { HighlightOutlined } from "@ant-design/icons";
const { TabPane } = Tabs;

const ChartSchematic = Loadable.Map({
  loader: {
    ChartSchematic: () =>
      import("../chart/interactions/basic-schematic/Chart.js"),
    data: () =>
      fetch("./client/examples/json/tachycardia-example.json").then((res) =>
        res.json()
      ),
  },
  loading() {
    return (
      <Row justify="center" align="middle" style={{ minHeight: "120px" }}>
        <Col>
          <Spin />
        </Col>
      </Row>
    );
  },
  render(loaded, props) {
    let ChartSchematic = loaded.ChartSchematic.default;
    let data = loaded.data;
    return <ChartSchematic {...props} entry={data} />;
  },
});

const ChartDeck = Loadable.Map({
  loader: {
    ChartDeck: () => import("../chart/interactions/basic-deck/Chart.js"),
    data: () =>
      fetch("./client/examples/json/tachycardia.json").then((res) =>
        res.json()
      ),
  },
  loading() {
    return (
      <Row justify="center" align="middle" style={{ minHeight: "120px" }}>
        <Col>
          <Spin />
        </Col>
      </Row>
    );
  },
  render(loaded, props) {
    let ChartDeck = loaded.ChartDeck.default;
    let data = loaded.data;
    return <ChartDeck {...props} entry={data} />;
  },
});

const ChartCollapse = Loadable.Map({
  loader: {
    ChartCollapse: () =>
      import("../chart/interactions/basic-collapse/Chart.js"),
    data: () =>
      fetch("./client/examples/json/tachycardia-collapse.json").then((res) =>
        res.json()
      ),
  },
  loading() {
    return (
      <Row justify="center" align="middle" style={{ minHeight: "120px" }}>
        <Col>
          <Spin />
        </Col>
      </Row>
    );
  },
  render(loaded, props) {
    let ChartCollapse = loaded.ChartCollapse.default;
    let data = loaded.data;
    return <ChartCollapse {...props} entry={data} />;
  },
});

const ChartSingle = Loadable.Map({
  loader: {
    ChartSingle: () => import("../chart/interactions/basic-single/Chart.js"),
    data: () =>
      fetch("./client/examples/json/tachycardia-single.json").then((res) =>
        res.json()
      ),
  },
  loading() {
    return (
      <Row justify="center" align="middle" style={{ minHeight: "120px" }}>
        <Col>
          <Spin />
        </Col>
      </Row>
    );
  },
  render(loaded, props) {
    let ChartSingle = loaded.ChartSingle.default;
    let data = loaded.data;
    return <ChartSingle {...props} entry={data} />;
  },
});

const ExamplesIndex = () => {
  const [startDemo, setStartDemo] = useState(false);
  const containerInactiveStyle = {
    borderRadius: "16px",
    maxHeight: "75vh",
    overflow: "hidden",
    position: "relative",
    boxShadow:
      "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
  };
  const containerActiveStyle = {
    borderRadius: "16px",
    boxShadow:
      "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
  };
  const containerStyle = startDemo
    ? containerActiveStyle
    : containerInactiveStyle;
  const overlayInactiveStyle = {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    background:
      "radial-gradient(circle, rgba(0,0,0,0.45) 0%, rgba(0,0,0,0.3) 100%)",
    backdropFilter: "blur(0.5px)",
    zIndex: "1300",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const overlayActiveStyle = {
    display: "none",
  };
  const overlayStyle = startDemo ? overlayActiveStyle : overlayInactiveStyle;

  return (
    <>
      <Row
        justify="center"
        style={{
          backgroundColor: "#f1f3f5",
          padding: "24px 12px 48px 12px",
        }}
      >
        <Col xs={24} sm={20} md={20} lg={18} xl={16} style={containerStyle}>
          <PageHeader
            className="site-page-header"
            title="Example: Tachycardia"
            style={{
              borderTopLeftRadius: "16px",
              borderTopRightRadius: "16px",
            }}
            ghost={false}
            extra={[
              <Button
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/examples/premium/example-medical-assessment");
                }}
                icon={<HighlightOutlined />}
                style={{ position: "relative", top: "15px" }}
              >
                More examples
              </Button>,
            ]}
          >
            {/* {startDemo > 0 && (
              <Alert
                message="This is an example chart used in a medical context"
                description="Click the 'Continue' button in the first step to start. You can
               view this chart in different lay-outs by selecting the tabs below."
                type="success"
                icon={
                  <ShareAltOutlined style={{ transform: "rotate(90deg)" }} />
                }
                showIcon
                closable
              />
            )} */}
          </PageHeader>
          <Tabs
            defaultActiveKey="1"
            type="line"
            style={{
              padding: "0",
              borderBottomLeftRadius: "16px",
              borderBottomRightRadius: "16px",
            }}
          >
            <TabPane
              tab="Schematic Chart"
              key="1"
              forceRender="true"
              className="background-lines"
              style={{ border: "1px solid #e9ecef" }}
            >
              <ChartSchematic
                firstNodeId="node1"
                startDemo={startDemo}
                useStartDemoProp="true"
              />
            </TabPane>
            <TabPane
              tab="Collapse Chart"
              key="2"
              forceRender="true"
              className="background-lines"
              style={{ border: "1px solid #e9ecef" }}
            >
              <div style={{ margin: "48px 0" }}>
                <ChartCollapse firstNodeId="nodeCollapse1" />
              </div>
            </TabPane>
            <TabPane
              tab="Deck Chart"
              key="3"
              forceRender="true"
              className="background-lines"
              style={{ border: "1px solid #e9ecef" }}
            >
              <div style={{ margin: "48px 0" }}>
                <ChartDeck firstNodeId="nodeDeck1" />
              </div>
            </TabPane>
            <TabPane
              tab="Single Chart"
              key="4"
              forceRender="true"
              className="background-lines"
              style={{ border: "1px solid #e9ecef" }}
            >
              <div style={{ margin: "48px 0" }}>
                <ChartSingle firstNodeId="nodeSingle1" />
              </div>
            </TabPane>
          </Tabs>
          <div style={overlayStyle}>
            <Button size="large" onClick={() => setStartDemo(true)}>
              Start demo chart
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ExamplesIndex;
