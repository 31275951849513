import React from "react";
import { Col, Row, Tabs, Typography } from "antd";
import TestclientChart from "../../client/testclient/json/reservation.json";
import ChartTestclient from "../../client/testclient/chart/Chart.js";
import ChartTestclientPharma from "../../client/testclient-pharma/interactions/chart/Chart.js";
import TestclientPharmaChart from "../../client/testclient-pharma/json/shipping-process.json";

const { Paragraph, Title } = Typography;
const { TabPane } = Tabs;

const FeatureBranding = () => {
  return (
    <>
      <Row
        justify="center"
        className="section"
        style={{ backgroundColor: "#f8f9fa", padding: "96px 0" }}
      >
        <Col xs={22} sm={20} md={20} lg={18} xl={16}>
          <Row justify="center" className="typography-nice alt">
            <Col style={{ textAlign: "center" }}>
              <Title
                level={2}
                style={{ textAlign: "center" }}
                className="typography-nice"
              >
                Go Premium
              </Title>
              <Paragraph
                style={{ fontSize: "125%", zIndex: "1" }}
                className="typography-nice"
              >
                We design and build custom lay-outs and
                <br /> love to implement existing designs, branding guidelines
                and/ or styleguides.
              </Paragraph>
            </Col>
          </Row>
          <Row justify="center" style={{ marginTop: "48px", padding: "0" }}>
            <Col xs={24} sm={20} md={16} lg={12} xl={12}>
              <Tabs
                defaultActiveKey="2"
                type="line"
                tabPosition="top"
                style={{
                  border: "1px solid #e9ecef",
                  borderRadius: "16px",
                  margin: "0 0 48px 0",
                  boxShadow: "0 -2px 6px rgba(0, 0, 0, 0.2)",
                }}
                className="background-lines"
              >
                <TabPane tab="Reservation tool" key="2" forceRender="true">
                  <ChartTestclient
                    entry={TestclientChart}
                    firstNodeId="node-reservation"
                  />
                </TabPane>
                {/* <TabPane
                  tab="Medical guideline"
                  key="1"
                  forceRender="true"
                  style={{ backgroundColor: "white", padding: "24px" }}
                  className="styles-katnh"
                >
                  <Chart entry={LongembolieExample} firstNodeId="node1katnh" />
                </TabPane> */}
                <TabPane tab="Shipping process" key="3" forceRender="true">
                  <ChartTestclientPharma
                    entry={TestclientPharmaChart}
                    firstNodeId="node-shipping1"
                  />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default FeatureBranding;
