import React from "react";
import { Col, Row, Typography } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import TextLoopIndex from "./TextLoopIndex";
const { Paragraph } = Typography;

const TextLoopBlock = () => {
  return (
    <>
      <Row justify="center" className="section section-clipped section-text">
        <Col xs={24} sm={20} md={20} lg={16} xl={12}>
          <Row>
            <Col span={24}>
              <TextLoopIndex />
              <Paragraph style={{ marginBottom: "0", marginTop: "36px" }}>
                <CheckOutlined
                  style={{ marginRight: "6px", color: "#40c057" }}
                />{" "}
                Forget maintaining static illustrations
                <br />
                <CheckOutlined
                  style={{ marginRight: "6px", color: "#40c057" }}
                />{" "}
                Create user-friendly content
                <br />
                <CheckOutlined
                  style={{ marginRight: "6px", color: "#40c057" }}
                />{" "}
                Improve your process
                <br />
                <CheckOutlined
                  style={{ marginRight: "6px", color: "#40c057" }}
                />{" "}
                Increase engagement
                <br />
                <span
                  role="img"
                  aria-label="wow emoji"
                  style={{ marginLeft: "1px", marginRight: "6px" }}
                >
                  🤩
                </span>{" "}
                Wow your users & clients
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default TextLoopBlock;
