import React from "react";
import { Typography } from "antd";
import TextLoop from "react-text-loop";

const { Title } = Typography;

const TextLoopIndex = () => {
  return (
    <>
      <Title level={2} style={{ lineHeight: "1.8" }} className="title-textloop">
        Professional charts
        <br />
        <span className="text-small" style={{ paddingLeft: "15px" }}>
          for your
        </span>
        <br />
        <TextLoop>
          <span className="text-mark">medical</span>
          <span className="text-mark">educational</span>
          <span className="text-mark">technical</span>
          <span className="text-mark">legal</span>
          <span className="text-mark">research</span>
          <span className="text-mark">training</span>
          <span className="text-mark">support</span>
          <span className="text-mark">financial</span>
        </TextLoop>
        <TextLoop interval={550}>
          <>website</>
          <>app</>
          <>publication</>
          <>documentation</>
          <>platform</>
          <>e-learning</>
          <>protocol</>
          <>wiki</>
          <>onboarding</>
          <>article</>
          <>manual</>
          <>paper</>
          <>tool</>
        </TextLoop>
        .
      </Title>
    </>
  );
};

export default TextLoopIndex;
