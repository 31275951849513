import React from "react";
import Seo from "../components/seo";
import LayoutMain from "../layouts/LayoutMain";
import { Divider } from "antd";
import HeadingHero from "../components/sections/HeadingHero";
import TextLoopBlock from "../components/sections/TextLoopBlock";
import ExamplesIndex from "../components/sections/ExamplesIndex";
import FeatureBlock from "../components/sections/FeatureBlock.js";
import FeatureEmbed from "../components/sections/FeatureEmbed.js";
import FeatureBranding from "../components/sections/FeatureBranding.js";
import LearnMore from "../components/sections/LearnMore.js";
// import ClientBlocks from "../components/sections/ClientBlocks";

const IndexPage = () => (
  <LayoutMain>
    <Seo
      title="Welcome to inFlow interactive charts"
      description="inFlow helps professional organisations create interactive decision-making tools such as flow charts, decision trees or algorithms. Improve your process and increase engagement with our world-class tools."
    />
    <HeadingHero />
    <ExamplesIndex />
    <TextLoopBlock />
    <FeatureBlock
      title="Mobile-first charts"
      description="inFlow charts are user-friendly and work great on any device. Our diverse catalog of layouts serve your use-case perfectly."
      title2="Rich content & widgets"
      description2="Convert your boring text to interactive content which is easy to consume. Engage your user."
      title3="Highly customizable"
      description3="Our charts are highly customizable and we love to create custom components. Provide the best possible user-experience."
    />
    <FeatureEmbed />
    <Divider dashed style={{ margin: "0" }} />
    <FeatureBranding />
    {/* <Divider dashed style={{ margin: "0" }} />
    <ClientBlocks /> */}
    <Divider dashed style={{ margin: "0" }} />
    <LearnMore />
  </LayoutMain>
);

export default IndexPage;
